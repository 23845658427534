@media screen and (max-width: 576px) {
  .wallet-balance-container {
    width: 100% !important;
  }

  .flex-row-wrapper {
    flex-direction: column !important;
  }

  .wallet-balance-button {
    margin: 10px 0 !important;
    max-width: 100% !important;
  }

  .wallet-balance-select {
    margin: 10px 0 !important;
    max-width: 100% !important;
  }
}

.wallet-balance-container {
  width: 350px;
  display: flex;
  flex-direction: column;
}

.wallet-balance-wrapper {
  background-color: rgba(245, 245, 245, 1);
  padding: 10px;
  width: calc(100% - 20px - 2px);
  height: calc(100px - 20px - 2px);
  border: 1px solid rgba(215, 215, 216, 1);
}

.wallet-balance-wrapper p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.wallet-balance-usd {
  font-size: 3rem;
  line-height: 3rem;
}

.wallet-balance-input {
  width: calc(100% - 6px);
  height: calc(30px - 4px);
  border: 1px solid rgba(225, 225, 225, 1);
  outline: none;
}

.wallet-balance-input:disabled {
  background: rgba(225, 225, 225, 1);
  cursor: not-allowed;
}

.wallet-balance-minimum-info {
  font-size: 0.8rem;
}

.wallet-balance-error-wrapper {
  background-color: rgba(248, 215, 218, 1);
  border-radius: 0.3rem;
}
.wallet-balance-success-wrapper {
  background-color: rgba(212, 237, 218, 1);
  border-radius: 0.3rem;
}
.wallet-balance-info-wrapper {
  background-color: rgba(209, 236, 241, 1);
  border-radius: 0.3rem;
}
.wallet-balance-error-wrapper span,
.wallet-balance-success-wrapper span,
.wallet-balance-info-wrapper span {
  font-size: 0.8rem;
  font-weight: bold;
}

.wallet-balance-button {
  color: rgba(255, 255, 255, 1);
  min-height: 42px;
  min-width: 120px;
  max-width: 60%;
  padding: 8px 30px;
  font-size: 16px;
  border-radius: 30px;
  border: 1px solid transparent;
  cursor: pointer;
  text-align: center;
  outline: none;
  text-decoration: none !important;
}

.wallet-balance-button:disabled {
  background: rgba(225, 225, 225, 1);
  cursor: not-allowed;
}

.wallet-balance-select {
  background-color: rgba(245, 245, 245, 1);
  max-width: 40%;
  min-height: 42px;
  border-radius: 30px;
  border: 1px solid transparent;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
}

.wallet-balance-link {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-align: center;
  outline: none;
  text-decoration: none !important;
}

.wallet-balance-link:disabled {
  color: rgba(200, 200, 200, 1);
  cursor: not-allowed;
}
