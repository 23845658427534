@media screen and (max-width: 915px) {
  .desktop-navbar {
    display: none !important;
  }
  .desktop-navbar-right-dropdown {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .desktop-navbar-right {
    display: none !important;
  }
}

header {
  background: rgba(24, 24, 24, 1);
  width: 100%;
  height: 60px;
  position: relative;
}

.navbar-logo {
  float: left;
  height: 60px;
  width: 60px;
  margin-left: 12px;
}

.navbar-logo a {
  background-image: url("/public/web128x128Blue.png");
  background-size: cover;
  background-position: 50% 50%;
  display: block;
  height: 50px;
  width: 50px;
  margin: 5px;
}

.desktop-navbar {
  padding: 0px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.desktop-navbar-right {
  float: right;
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 100%;
  width: fit-content;
  position: relative;
}

.desktop-navbar-right-cart {
  width: 28px;
  height: 100%;
}

.cart-icon {
  width: 28px;
  height: 28px;
  background-image: url("/public/default-cart.png");
  background-size: cover;
  cursor: pointer;
}

.navbar-right-cart-icon {
  margin-top: 16px;
}

.desktop-navbar-right-balance {
  min-width: 56px;
  height: 100%;
  margin-left: 16px;
}

.desktop-navbar-right-balance div {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.desktop-navbar-right-balance div p {
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  margin: 0 0 4px 6px;
}

.desktop-navbar-right-profile {
  width: 44px;
  height: 100%;
  margin-left: 16px;
  margin-right: 16px;
}

.desktop-navbar-right-profile div {
  margin-top: 8px;
  border-radius: 50%;
}

.user-profile {
  width: 40px;
  height: 40px;
  background-image: url("/public/default-portrait.png");
  background-size: cover;
  cursor: pointer;
}

.desktop-navbar-right-dropdown {
  position: absolute;
  z-index: 1;
  top: 60px;
  right: 0px;
  min-width: 220px;
  max-width: 225px;
  background: #fff;
  border: solid 1px #ddd;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .15);
  padding: 20px 0;
  margin: 0;
}

.desktop-navbar-right-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.desktop-navbar-right-dropdown ul li {
  line-height: 18px;
  min-height: 34px;
  padding: 0;
  margin: 0;
  width: 100%;
}

.desktop-navbar-right-dropdown ul li:not(:first-child) {
  cursor: pointer;
}

.desktop-navbar-right-dropdown ul li:not(:first-child):hover {
  background: rgba(0, 0, 0, 0.05);
}

.desktop-navbar-right-dropdown-link {
  color: rgba(0, 0, 0, 1);
  text-decoration: none;
  display: block;
  width: calc(100% - 44px);
  padding: 8px 22px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.desktop-navbar-right-dropdown-link:not(:first-child) {
  cursor: pointer;
}

.desktop-navbar-right-dropdown-link-non-logged {
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  display: block;
  font-weight: 700;
  padding: 18px 20px;
  height: 24px;
}

.desktop-navbar .desktop-navbar-main-menu {
  float: left;
  list-style: none;
  margin: 0;
  padding: 0px;
  height: 100%;
  margin-left: 20px;
}

.desktop-navbar .desktop-navbar-main-menu li {
  float: left;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 32px;
}

.navbar-link {
  color: rgba(255, 255, 255, 0.45);
  text-decoration: none;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}

.navbar-link:hover {
  color: rgba(255, 255, 255, .75);
}
