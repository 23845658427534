body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  margin: 0;
  font-size: 16px;
}

main {
  width: 100%;
  min-height: calc(100vh - 60px);
  margin: 0;
  padding: 0;
}

section {
  max-width: 512px;
  width: auto;
  padding: 4rem;
  margin: 0 auto;
}
section h1 {
  text-align: center;
  font-size: 3rem;
  margin: 0;
}
section h2 {
  text-align: center;
  font-size: 2rem;
  margin: 0;
}

/* padding */
.p-0 {
  padding: 0;
}
.p-2 {
  padding: 0.5rem;
}
.p-4 {
  padding: 1rem;
}
.p-8 {
  padding: 2rem;
}
.p-16 {
  padding: 4rem;
}

/* margin */
.m-0 {
  margin: 0;
}

/* margin bottom */
.mb-0 {
  margin-bottom: 0;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mb-8 {
  margin-bottom: 2rem;
}
.mb-16 {
  margin-bottom: 4rem;
}

/* margin top */
.mt-0 {
  margin-top: 0;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-8 {
  margin-top: 2rem;
}
.mt-16 {
  margin-top: 4rem;
}
/* margin left */
.ml-0 {
  margin-left: 0;
}
.ml-2 {
  margin-left: 0.5rem;
}
.ml-4 {
  margin-left: 1rem;
}
.ml-8 {
  margin-left: 2rem;
}
.ml-16 {
  margin-left: 4rem;
}
/* margin right */
.mr-0 {
  margin-right: 0;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mr-4 {
  margin-right: 1rem;
}
.mr-8 {
  margin-right: 2rem;
}
.mr-16 {
  margin-right: 4rem;
}

.dark-gray {
  background: rgba(166, 166, 166, 1);
}

.dark-blue {
  background: rgba(33, 188, 207, 1);
}

.font-red {
  color: rgba(255, 0, 0, 1);
}

.flex-row-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex-center {
  display: flex;
  justify-content: center;
}
