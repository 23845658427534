@media screen and (max-width: 915px) {
  .mobile-controlls {
    display: block !important;
  }
  .mobile-navbar {
    display: block !important;
  }
}

.mobile-controlls {
  display: none;
  float: right;
  min-width: 85px;
  max-width: 185px;
  height: 100%;
  margin-right: 16px;
}

.mobile-controlls-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}

.mobile-controlls-hamburguer {
  height: 22px;
  width: 22px;
  background-image: url("/public/default-hamburguer.png");
  background-size: cover;
  margin-top: 2px;
  cursor: pointer;
}

.mobile-navbar {
  display: none;
  position: fixed;
  z-index: 1;
  background: rgba(24, 24, 24, 1);
  padding: 0px;
  height: 100vh;
  width: 300px;
  right: 0px;
  overflow: hidden;
  transition: .15s linear;
  visibility: hidden;
  transform: translateX(100%);
}

.mobile-navbar-slide {
  visibility: visible;
  transform: translateX(0%);
}

.mobile-navbar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 10px;
}

.mobile-navbar ul li {
  margin: 0;
  padding: 0 24px;
  height: 36px;
  display: flex;
  align-items: center;
  /* justify-content: flex-start; */
  border-left: 5px solid transparent;
  width: calc(100% - 48px - 5px);
  max-width: calc(300px - 48px - 5px);
}

.mobile-navbar-head {
  justify-content: space-between !important;
}

.mobile-navbar-head-ellipsis {
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mobile-navbar-head-white {
  color: rgba(255, 255, 255, 1);
}

.mobile-navbar-head-close-button {
  font-size: 20px;
  margin-bottom: 2px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.25) !important;
}

.mobile-navbar-head-close-button:hover {
  color: rgba(255, 255, 255, .75) !important;
}

.mobile-navbar ul li hr {
  height: 1.2px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.25);
  border: 0;
}
