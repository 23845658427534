@media screen and (max-width: 576px) {
  footer .footer-top {
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

footer {
  color: #444;
  text-align: center;
  width: 100%;
  background: rgba(24, 24, 24, 1);
  color: rgba(255, 255, 255, 0.45);
}
footer .footer-top {
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
}
footer .footer-bottom {
  padding: 24px 0px;
  border-top: 1px solid rgba(51, 51, 51, 1);
  font-size: 14px;
}

footer .footer-top ul {
  display: inline-block;
  vertical-align: top;
  padding: 0px;
  list-style: none;
  text-align: left;
  width: auto;
  margin: 16px 0px;
  min-width: 100px;
}


footer .footer-top ul li {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  font-size: 14px;
  font-weight: 400;
  line-height: 24.5px;
}
footer .footer-top ul li h6 {
  color: rgba(255, 255, 255, 0.9);
  margin: 0px 0px 16px;
  font-size: 1.3rem;
  line-height: 1.1;
  font-weight: 700;
}
footer .footer-top ul li a {
  color: rgba(255, 255, 255, 0.45);
  text-decoration: none;
  cursor: pointer;
}
footer .footer-top ul li a:hover {
  color: rgba(255, 255, 255, 1);
}
